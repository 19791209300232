import React, { useEffect, useState, useContext } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { BACKEND_API, IS_MAINTENANCE } from "../constants/backendApi";
import { appVersion } from "../version";

//Page
import CurrentCreditScreen from "../pages/CurrentCreditScreen/CurrentCreditScreen";
import DocCertificationScreen from "../pages/DocCertificationScreen/DocCertificationScreen";
import CancellationProcessScreen from "../pages/CancellationProcessScreen/CancellationProcessScreen";
import CancellationScreen from "../pages/CancellationScreen/CancellationScreen";
import ClientHome from "../pages/ClientHome/ClientHome";
import ProfileScreen from "../pages/ProfileScreen/ProfileScreen";
import SimulatorScreen from "../pages/SimulatorScreen/SimulatorScreen";
import CreateCreditScreen from "../pages/CreateCreditScreen/CreateCreditScreen";
import CertificationScreen from "../pages/CertificationScreen/CertificationScreen";
import AttachDocScreen from "../pages/AttachDocScreen/AttachDocScreen";
import AttachPolicyScreen from "../pages/AttachPolicyScreen/AttachPolicyScreen";
import MoreInfoDataFormScreen from "../pages/MoreInfoDataFormScreen/MoreInfoDataFormScreen";
import RenovationTableScreen from "../pages/RenovationTableScreen/RenovationTableScreen";
import CreditsInProcessTableScreen from "../pages/CreditsInProcessTableScreen/CreditsInProcessTableScreen";
import CreateCreditValidateDocumentScreen from "../pages/CreateCreditValidateDocumentScreen/CreateCreditValidateDocumentScreen";
import VerificationUserDataScreen from "../pages/VerificationUserDataScreen/VerificationUserDataScreen";
import MoreInfoDataRenovationScreen from "../pages/MoreInfoDataRenovationScreen/MoreInfoDataRenovationScreen";
import CreateCreditRenovationScreen from "../pages/CreateCreditRenovationScreen/CreateCreditRenovationScreen";
import PaymentMethodScreen from "../pages/PaymentMethodScreen/PaymentMethodScreen";
import SurveyScreen from "../pages/SurveyScreen/SurveyScreen";
import AttachAppendixScreen from "../pages/AttachAppendixScreen/AttachAppendixScreen";

//Context
import { UserDataContext } from "../context/UserDataContext";
import { LoadingContext } from "../context/LoadingContext";
import { AuthContext } from "../context/AuthContext";

//Utils
import { authTypes } from "../types/authTypes";

const AppRouter = () => {
  const location = useLocation();

  const { setLoading } = useContext(LoadingContext);

  const { dispatch } = useContext(AuthContext);

  const token = localStorage.getItem("token");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [currentUser, setCurrentUser] = useState({});

  const [currentUserMenu, setCurrentUserMenu] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      fetch(`${BACKEND_API}/api/user?vs=${appVersion}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then(({ data }) => {
          if (data) {
            // localStorage.setItem("temp_profile", data?.profile);
            localStorage.setItem("temp_doc", data?.document);
            // localStorage.setItem("userId", data?.userId)
            console.log(data);
            setCurrentUser(data);
          } else {
            localStorage.clear();
          }
        })
        .finally(() => {
          // const tempProfile = localStorage.getItem("temp_profile");
          const tempDoc = localStorage.getItem("temp_doc");
          if (IS_MAINTENANCE && tempDoc !== "900344345") {
            dispatch({ type: authTypes.logout });
            localStorage.removeItem("token");
            // localStorage.removeItem("temp_profile");
            localStorage.removeItem("temp_doc");
          }
          location.pathname !== "/admin/panel" && setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, [location?.pathname, token, setLoading]);

  useEffect(() => {
    try {
      fetch(`${BACKEND_API}/api/main`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message === "informacion menu") {
            const isManager =
              localStorage.getItem("userProfile") === "Gestor"
                ? true
                : false || false;

            let filteredMenu = [];

            if (isManager) {
              filteredMenu = data.data.filter((item) => {
                return item.title === "CREAR CRÉDITO";
              });
            } else {
              filteredMenu = data.data.filter((item) => {
                return windowWidth >= 1024 || item.mobile !== false;
              });
            }

            setCurrentUserMenu(filteredMenu);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [windowWidth, token]);

  return (
    <UserDataContext.Provider value={{ currentUser, setCurrentUser }}>
      <Routes>
        <Route
          end
          path="/admin/panel"
          element={
            <ClientHome userData={currentUser} userMenuData={currentUserMenu} />
          }
        />
        <Route
          end
          path="/admin/perfil"
          element={
            <ProfileScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
              token={token}
            />
          }
        />
        <Route
          end
          path="/admin/simulador-credito"
          element={
            <SimulatorScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        <Route
          end
          path="/admin/crear-credito/validar-documento"
          element={
            <CreateCreditValidateDocumentScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        <Route
          end
          path="/admin/crear-credito/adjuntar-documento"
          element={
            <AttachDocScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />

        <Route
          end
          path="/admin/crear-credito/adjuntar-poliza"
          element={
            <AttachPolicyScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />

        <Route
          end
          path="/admin/crear-credito/adjuntar-anexo"
          element={
            <AttachAppendixScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        <Route
          end
          path="/admin/crear-credito/verificar-datos"
          element={
            <VerificationUserDataScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        <Route
          end
          path="/admin/crear-credito/formulario-renovacion"
          element={
            <MoreInfoDataRenovationScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        <Route
          end
          path="/admin/crear-credito/formulario"
          element={
            <MoreInfoDataFormScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        <Route
          end
          path="/admin/crear-credito/formulario-renovacion-confirmar"
          element={
            <CreateCreditRenovationScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        <Route
          end
          path="/admin/crear-credito/formulario-confirmar"
          element={
            <CreateCreditScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        <Route
          end
          path="/admin/creditos-mora"
          element={
            <CertificationScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        <Route
          end
          path="/admin/creditos-proceso"
          element={
            <CreditsInProcessTableScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        <Route
          end
          path="/admin/renovaciones-creditos"
          element={
            <RenovationTableScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        <Route
          end
          path="/admin/metodos-de-pago"
          element={
            <PaymentMethodScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        <Route
          end
          path="/admin/crear-credito/encuesta"
          element={
            <SurveyScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        <Route
          end
          path="/admin/creditos-cancelacion"
          element={
            <CancellationScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />
        <Route
          end
          path="/admin/creditos-proceso-cancelacion-avisado"
          element={
            <CancellationProcessScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />

        <Route
          end
          path="/admin/certificaciones"
          element={
            <DocCertificationScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />

        <Route
          end
          path="/admin/creditos-vigentes"
          element={
            <CurrentCreditScreen
              userData={currentUser}
              userMenuData={currentUserMenu}
            />
          }
        />

        <Route path="*" element={<Navigate to="/admin/panel" />} />
      </Routes>
    </UserDataContext.Provider>
  );
};

export default AppRouter;
