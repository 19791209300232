import React, { useEffect, useContext, useState } from "react";
import { BACKEND_API } from "../../../constants/backendApi";

//Style
import "./DocCertificationBody.css";

//Components
import CreditDetailsTableComponent from "../../CreditDetailsTableComponent/CreditDetailsTableComponent";

//Context
import { LoadingContext } from "../../../context/LoadingContext";
import { PopupContext } from "../../../context/PopupContext";

//hooks
import useDebounce from "../../../hooks/useDebounce";

const DocCertificationBody = ({ userData }) => {
  const { setLoading } = useContext(LoadingContext);

  const [maxData, setMaxData] = useState(10);

  const [initPage, setInitPage] = useState(0);

  const token = localStorage.getItem("token");

  const [peaceAndBondCreditsTotal, setPeaceAndBondCreditsTotal] = useState(0);

  const [currentCreditsTotal, setCurrentCreditsTotal] = useState(0);

  const [creditList, setCreditList] = useState([]);

  const [totalCredits, setTotalCredits] = useState(0);

  const { setShowPopup, setPopupData } = useContext(PopupContext);

  const [filterData, setFilterData] = useState({
    type: "",
    document_plate: "",
    advisor: "",
    insurer: "",
    credit_state: "",
    next_to_renew: "",
    type_certification: "in_process",
  });

  const tableFields = [
    {
      name: "Altura",
    },
    {
      name: "Nombre Cliente",
    },
    {
      name: "No. de Documento",
    },
    {
      name: "Aseguradora",
    },
    {
      name: "No. de Poliza",
    },
    {
      name: "Placa/Detalle de Riesgo",
    },
    {
      name: "Acciones",
    },
  ];

  const formatNumber = (number) => {
    return new Intl.NumberFormat("es-ES").format(number);
  };

  useEffect(() => {
    try {
      const filterBody = {
        Filtro: filterData.document_plate,
        EstadoConsulta: "",
        IdAseguradora: filterData.insurer,
        Estado: "",
        AllData: false,
        Vigente: false,
        Renovacion: false,
        Mora: false,
        EnProceso: true,
        NumeroRegistros: maxData,
        Cancelados: false,
        EnProcesoCancelacion: false,
        Inicial: initPage,
        Anio: "0",
        Mes: "0",
      };

      try {
        fetch(`${BACKEND_API}/api/credits`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(filterBody),
        })
          .then((res) => res.json())
          .then(({ data }) => {
            if (data?.TotalRegistros) {
              const totalCurrent =
                data?.TotalRegistros + data?.TotalCreditosVigentes;

              const totalCurrentFormatted =
                totalCurrent.toLocaleString("es-CO");

              setCurrentCreditsTotal(totalCurrentFormatted);
            } else {
              setCurrentCreditsTotal(0);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }

    try {
      const filterBody = {
        Filtro: filterData.document_plate,
        EstadoConsulta: "",
        IdAseguradora: filterData.insurer,
        Estado: "",
        AllData: false,
        Vigente: false,
        Renovacion: false,
        Mora: false,
        EnProceso: false,
        NumeroRegistros: maxData,
        Cancelados: false,
        EnProcesoCancelacion: false,
        PazySalvo: true,
        Inicial: initPage,
        Anio: "0",
        Mes: "0",
      };

      try {
        fetch(`${BACKEND_API}/api/credits`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(filterBody),
        })
          .then((res) => res.json())
          .then(({ data }) => {
            if (data?.TotalRegistros) {
              setPeaceAndBondCreditsTotal(data.TotalRegistros);
            } else {
              setPeaceAndBondCreditsTotal(0);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const sendCertificate = (creditData) => {
    const body = {
      data: [creditData],
    };

    if (body.data.length !== 0) {
      setLoading(true);
      try {
        fetch(`${BACKEND_API}/api/certificate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data && data.message === "Certificados") {
              setShowPopup(true);
              setPopupData({
                title: "Enviado Exitosamente",
                info: `Tu certificado ha sido enviado al siguiente correo electrónico: ${userData.email}`,
                type: "correct",
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const downloadCertificate = (creditData) => {
    try {
      fetch(`${BACKEND_API}/api/export-certificate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(creditData),
      })
        .then(async (res) => {
          if (!res.ok) {
            throw new Error("Error al descargar el certificado");
          }

          const contentType = res.headers.get("Content-Type");
          if (contentType !== "application/pdf") {
            const responseText = await res.text();
            console.error("Respuesta no PDF:", responseText);
            throw new Error(
              `Error: el servidor no devolvió un PDF. Tipo de contenido: ${contentType}`
            );
          }

          return res.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${creditData.tipoDoc}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const debouncedSearch = useDebounce((filterData) => {
    setLoading(true);

    const filterBody = {
      Filtro: filterData.document_plate,
      EstadoConsulta: "",
      IdAseguradora: filterData.insurer,
      Estado: "",
      AllData: false,
      Vigente: filterData.type_certification === "current" ? true : false,
      Renovacion: false,
      Mora: false,
      EnProceso: filterData.type_certification === "in_process" ? true : false,
      PazySalvo:
        filterData.type_certification === "peace_and_bond" ? true : false,
      NumeroRegistros: maxData,
      Cancelados: false,
      EnProcesoCancelacion: false,
      Inicial: initPage,
      Anio: "0",
      Mes: "0",
    };

    try {
      fetch(`${BACKEND_API}/api/credits`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(filterBody),
      })
        .then((res) => res.json())
        .then(({ data }) => {
          if (data?.Creditos) {
            const creditsNewArr = data.Creditos.map((credit) => [
              {
                field_content: `${
                  filterData.type_certification !== "peace_and_bond"
                    ? credit.NumCuota
                    : credit.No_Cuotas
                } de ${credit.No_Cuotas}`,
              },
              {
                field_content: credit.NombreCliente,
              },
              {
                field_content: credit.Document,
              },
              {
                field_content: credit.Aseguradora,
              },
              {
                field_content: credit.NoPoliza,
              },
              {
                field_content: credit.Placa
                  ? credit.Placa
                  : credit.detalleRiesgo
                  ? credit.detalleRiesgo
                  : "N/A",
              },
              {
                is_action_btn: true,
                btns: [
                  credit.Estado === "Vigente" && {
                    function: () =>
                      sendCertificate({
                        creditId: credit.credito,
                        clientId: credit.IdClient,
                        nameCertificate: "Estado_del_credito",
                      }),
                    title: "Enviar Estado del Crédito",
                    icon_src:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/certification-module-icon-white.webp",
                    icon_src_hover:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/certification-module-icon.webp",
                  },
                  credit.Estado === "Vigente" && {
                    function: () =>
                      downloadCertificate({
                        idCredito: credit.IdCredit,
                        tipoDoc: "Estado_del_credito",
                      }),
                    title: "Descargar Estado del Crédito",
                    icon_src:
                      "https://pagina-crediseguro.s3.us-east-1.amazonaws.com/portal-crediseguro/assets/icons/download-icon-white.webp",
                    icon_src_hover:
                      "https://pagina-crediseguro.s3.us-east-1.amazonaws.com/portal-crediseguro/assets/icons/download-icon.webp",
                  },
                  (credit.Estado === "Vigente" ||
                    credit.Estado === "En proceso") && {
                    function: () =>
                      sendCertificate({
                        creditId: credit.credito,
                        clientId: credit.IdClient,
                        nameCertificate: "Recibo_de_pago",
                      }),
                    title: "Enviar Recibo de Pago",
                    icon_src:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/certification-module-icon-white.webp",
                    icon_src_hover:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/certification-module-icon.webp",
                  },
                  (credit.Estado === "Vigente" ||
                    credit.Estado === "En proceso") && {
                    function: () =>
                      downloadCertificate({
                        idCredito: credit.IdCredit,
                        tipoDoc: "Recibo_de_pago",
                      }),
                    title: "Descargar Recibo de Pago",
                    icon_src:
                      "https://pagina-crediseguro.s3.us-east-1.amazonaws.com/portal-crediseguro/assets/icons/download-icon-white.webp",
                    icon_src_hover:
                      "https://pagina-crediseguro.s3.us-east-1.amazonaws.com/portal-crediseguro/assets/icons/download-icon.webp",
                  },
                  credit.Estado.includes("Paz y Salvo") && {
                    function: () =>
                      sendCertificate({
                        creditId: credit.credito,
                        clientId: credit.IdClient,
                        nameCertificate: "paz_y_salvo",
                      }),
                    title: "Enviar Paz y Salvo",
                    icon_src:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/certification-module-icon-white.webp",
                    icon_src_hover:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/certification-module-icon.webp",
                  },
                  credit.Estado.includes("Paz y Salvo") && {
                    function: () =>
                      downloadCertificate({
                        idCredito: credit.IdCredit,
                        tipoDoc: "paz_y_salvo",
                      }),
                    title: "Descargar Paz y Salvo",
                    icon_src:
                      "https://pagina-crediseguro.s3.us-east-1.amazonaws.com/portal-crediseguro/assets/icons/download-icon-white.webp",
                    icon_src_hover:
                      "https://pagina-crediseguro.s3.us-east-1.amazonaws.com/portal-crediseguro/assets/icons/download-icon.webp",
                  },
                ].filter(Boolean),
              },
            ]);

            setTotalCredits(data.TotalRegistros);
            setCreditList(creditsNewArr);
          } else {
            setCurrentCreditsTotal(0);
            setPeaceAndBondCreditsTotal(0);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, 500);

  useEffect(() => {
    debouncedSearch(filterData);
    // eslint-disable-next-line
  }, [maxData, initPage, filterData]);

  const handleGetFilters = (e) => {
    setFilterData({
      ...filterData,
      type: e.target.value !== "" ? "Documento_Placa" : "",
      [e.target.name]: e.target.value,
    });

    if (
      e.target.name === "advisor" ||
      e.target.name === "insurer" ||
      e.target.name === "credit_state" ||
      e.target.name === "type_certification"
    ) {
      setFilterData({
        ...filterData,
        type: "FiltrosVarios",
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleCleanFilters = () => {
    setFilterData({
      type: "",
      document_plate: "",
      advisor: "",
      insurer: "",
      credit_state: "",
      next_to_renew: "",
      type_certification: "in_process",
    });
  };

  const getMaxDataValue = (maxDataVal) => {
    setMaxData(maxDataVal);
  };

  const getPaginationData = (initPageVal) => {
    setInitPage(initPageVal);
  };

  return (
    <section className="renovation-table-section">
      <div className="renovation-table-subcontainer">
        <div className="rt-title-container">
          <h1 className="rt-title">Certificados de Créditos</h1>
        </div>
        <div className="rt-description-txt-container">
          <p className="rtdt-txt">
            En esta sección, encontrarás los certificados de recibos de pago y
            los estados de crédito para los créditos vigentes, así como el
            certificado de paz y salvo para los créditos que han finalizado
            correctamente su financiación.
          </p>
          <div className="stats-container">
            <div className="stats-subcontainer">
              <h1 className="stats-title">Total de Créditos en Paz y Salvo</h1>
              <p className="stats-txt">
                {formatNumber(peaceAndBondCreditsTotal)}
              </p>
            </div>
            <div className="stats-subcontainer">
              <h1 className="stats-title">Total de Créditos Vigentes</h1>
              <p className="stats-txt">{currentCreditsTotal}</p>
            </div>
          </div>
        </div>
        <div className="filter-elms-container">
          <div className="input-container">
            <form onChange={handleGetFilters} className="filter-form-container">
              <label>
                <h1 className="label-title">Número de Documento o Placa:</h1>
                <input
                  className="filter-input-search"
                  type="text"
                  placeholder="Numero de Documento o Placa"
                  title="Numero de Documento o Placa"
                  name="document_plate"
                  value={filterData.document_plate}
                />
              </label>
              <label>
                <h1 className="label-title">Tipo de Certificado:</h1>
                <select
                  name="type_certification"
                  id="type_certification"
                  value={filterData.type_certification}
                >
                  <option value="in_process" selected>
                    En Proceso
                  </option>
                  <option value="current">Vigentes</option>
                  <option value="peace_and_bond">Paz y Salvo</option>
                </select>
              </label>
            </form>
            <button
              className="c-btn-search"
              title="Limpiar Filtros"
              onClick={handleCleanFilters}
            >
              Limpiar Filtros
            </button>
          </div>
        </div>
        <div className="credit-details-table-supercontainer">
          <CreditDetailsTableComponent
            getMaxData={getMaxDataValue}
            maxData={maxData}
            getPaginationInit={getPaginationData}
            credits={creditList}
            totalCreditsNum={totalCredits}
            filterData={filterData}
            fields={tableFields}
          />
        </div>
      </div>
    </section>
  );
};

export default DocCertificationBody;
