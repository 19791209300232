import React, { useState, useEffect, useContext } from "react";
import { utils, writeFile } from "xlsx";
import { BACKEND_API } from "../../constants/backendApi";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

//Style
import "./RenovationTableBody.css";

//Components
import CreditDetailsTableComponent from "../CreditDetailsTableComponent/CreditDetailsTableComponent";

//Context
import { LoadingContext } from "../../context/LoadingContext";

//hooks
import useDebounce from "../../hooks/useDebounce";

const RenovationTableBody = ({ getDataFromCreditTable, userData }) => {
  const { setLoading } = useContext(LoadingContext);

  const [maxData, setMaxData] = useState(10);

  const [initPage, setInitPage] = useState(0);

  const navigate = useNavigate();

  const [creditList, setCreditList] = useState([]);

  const [creditListExcel, setCreditListExcel] = useState([]);

  const [totalCredits, setTotalCredits] = useState(0);

  const [totalCreditCurrent, setTotalCreditCurrent] = useState([]);

  const [totalCreditRenovation, setTotalCreditRenovation] = useState([]);

  const [nextToRenew] = useState("");

  const [filterData, setFilterData] = useState({
    type: "Renovacion",
    document_plate: "",
    advisor: "",
    insurer: "",
    credit_state: "",
    year: new Date().getFullYear().toString(),
    month: (new Date().getMonth() + 1).toString(),
  });

  const tableFields = [
    {
      name: "Altura",
    },
    {
      name: "Nombre Cliente",
    },
    {
      name: "No. de Documento",
    },
    {
      name: "Aseguradora",
    },
    {
      name: "No. de Poliza",
    },
    {
      name: "Placa/Detalle de Riesgo",
    },
    {
      name: "Acciones",
    },
  ];

  const token = localStorage.getItem("token");

  useEffect(() => {
    const selectNextToRenew = document.getElementById("next_to_renew");

    const getMonthName = (month) => {
      const monthsArr = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      return monthsArr[month];
    };

    if (selectNextToRenew) {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();

      for (let i = 0; i < 3; i++) {
        const option = document.createElement("option");

        if (i === 0) {
          option.text = getMonthName(currentMonth);
          option.value = `${currentMonth + 1}/${currentYear}`;
        } else {
          const nextMonth = (currentMonth + i) % 12;
          const nextYear = currentYear + Math.floor((currentMonth + i) / 12);

          option.text = getMonthName(nextMonth);
          option.value = `${nextMonth + 1}/${nextYear}`;
        }

        selectNextToRenew.appendChild(option);
      }
    }

    try {
      const selectInsurer = document.getElementById("insurer");

      fetch(`${BACKEND_API}/api/insurance-companies`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then(({ data }) => {
          data.map((insurer) => {
            const option = document.createElement("option");
            option.text = insurer.NombreAseguradora;
            option.value = insurer.IdAseguradora;
            selectInsurer.appendChild(option);

            const optionsArray = Array.from(selectInsurer.options);

            return optionsArray.sort((a, b) => a.text.localeCompare(b.text));
          });
        });
    } catch (err) {
      console.log(err);
    }

    const filterBody = {
      Filtro: filterData.document_plate,
      EstadoConsulta: "",
      IdAseguradora: filterData.insurer,
      Estado: "",
      AllData: true,
      Vigente: false,
      Renovacion: true,
      Mora: false,
      EnProceso: false,
      NumeroRegistros: maxData,
      Cancelados: false,
      EnProcesoCancelacion: false,
      Inicial: initPage,
      Anio: filterData.year,
      Mes: filterData.month,
    };

    try {
      fetch(`${BACKEND_API}/api/credits`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(filterBody),
      })
        .then((res) => res.json())
        .then(({ data }) => {
          if (data.Creditos) {
            setCreditListExcel(data.Creditos);
          } else {
            setCreditList([]);
            setTotalCredits(0);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }

    try {
      const year = new Date().getFullYear();

      try {
        const filterCurrentBody = {
          Filtro: filterData.document_plate,
          EstadoConsulta: "",
          IdAseguradora: filterData.insurer,
          Estado: "",
          AllData: false,
          Renovacion: false,
          Vigente: true,
          Mora: false,
          EnProceso: false,
          NumeroRegistros: maxData,
          Cancelados: false,
          EnProcesoCancelacion: false,
          Inicial: initPage,
          Anio: year,
          Mes: 1,
        };

        fetch(`${BACKEND_API}/api/credits`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(filterCurrentBody),
        })
          .then((res) => res.json())
          .then(({ data }) => {
            if (data.Creditos) {
              setTotalCreditCurrent(data.TotalCreditosVigentes);
            }
          });
      } catch (err) {
        console.log(err);
      }

      for (let i = 0; i < 12; i++) {
        try {
          const filterRenovationBody = {
            Filtro: filterData.document_plate,
            EstadoConsulta: "",
            IdAseguradora: filterData.insurer,
            Estado: "",
            AllData: false,
            Renovacion: true,
            Vigentes: false,
            Mora: false,
            EnProceso: false,
            NumeroRegistros: maxData,
            Inicial: initPage,
            Anio: year,
            Mes: i + 1,
          };

          fetch(`${BACKEND_API}/api/credits`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(filterRenovationBody),
          })
            .then((res) => res.json())
            .then(({ data }) => {
              if (data.Creditos) {
                setTotalCreditRenovation((prevTotalCreditRenovation) => [
                  +prevTotalCreditRenovation + Number(data.TotalRegistros),
                ]);
              }
            });
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line
  }, []);

  const handleActionRenovateCredit = (doc) => {
    localStorage.setItem("latestStep", "validate-document");

    const uuid = uuidv4();
    const valuesArr = uuid.split("-");

    const id = valuesArr[0] + valuesArr[1];

    localStorage.setItem("form_id", id);
    localStorage.setItem("user_document", doc);
    try {
      fetch(`https://crediseguro-back.click/getDocument/${doc}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data && data.message === "Información obtendida exitosamente") {
            const userData = {
              phone: data.data.Phone,
              email: data.data.Email,
              num_doc: data.data.NoDocumento,
            };

            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("processType", "renovation");
            localStorage.setItem("isCreditInProcess", true);
            localStorage.setItem("fromRenovationTable", true);
            navigate("/admin/crear-credito/adjuntar-poliza");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddNote = (creditData) => {
    try {
      fetch(`${BACKEND_API}/api/reason`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message === "reason") {
            let reasonArr = data.data.map((reason, i) => {
              return {
                value: reason,
                txt: reason,
              };
            });

            getDataFromCreditTable(
              {
                title: "Motivo de no renovación",
                fields: reasonArr,
                credit_id: creditData,
              },
              true,
              {
                type: "add_observation",
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const debouncedSearch = useDebounce((filterData) => {
    setLoading(true);

    const filterBody = {
      Filtro: filterData.document_plate,
      EstadoConsulta: "",
      IdAseguradora: filterData.insurer,
      Estado: "",
      AllData: false,
      Renovacion: true,
      Mora: false,
      EnProceso: false,
      NumeroRegistros: maxData,
      Inicial: initPage,
      Anio: filterData.year,
      Mes: filterData.month,
    };

    if (
      filterData.type === "FiltrosVarios" &&
      filterData.advisor === "" &&
      filterData.insurer === "" &&
      filterData.credit_state === ""
    ) {
      filterBody.TipoFiltro = "Renovacion";
    }

    try {
      fetch(`${BACKEND_API}/api/credits`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(filterBody),
      })
        .then((res) => res.json())
        .then(({ data }) => {
          if (data.Creditos) {
            const filteredCredits = data.Creditos.filter(
              (credit) => credit.MotivoNoRenovacion === null
            );

            console.log(filteredCredits);

            const creditsNewArr = filteredCredits.map((credit) => [
              {
                field_content: credit.NumCuota + " de " + credit.No_Cuotas,
              },
              {
                field_content: credit.NombreCliente,
              },
              {
                field_content: credit.Document,
              },
              {
                field_content: credit.Aseguradora,
              },
              {
                field_content: credit.DiasMora,
              },
              {
                field_content: credit.NoPoliza,
              },
              {
                field_content: credit.Placa
                  ? credit.Placa
                  : credit.detalleRiesgo,
              },
              {
                is_action_btn: true,
                btns: [
                  {
                    function: () => handleActionRenovateCredit(credit.Document),
                    title: "Renovar Credito",
                    icon_src:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/renovation-icon-white.webp",
                    icon_src_hover:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/renovation-icon.webp",
                  },
                  credit.MotivoNoRenovacion === null && {
                    function: () => handleAddNote(credit.IdCredit),
                    title: "Agregar Observación",
                    icon_src:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/add-note-icon.webp?v=1",
                    icon_src_hover:
                      "https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/add-note-icon-hover.webp",
                  },
                ].filter(Boolean),
              },
            ]);

            setTotalCredits(data.TotalRegistros);
            setCreditList(creditsNewArr);
          } else {
            setCreditList([]);
            setTotalCredits(0);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, 500);

  useEffect(() => {
    debouncedSearch(filterData);
    // eslint-disable-next-line
  }, [maxData, initPage, filterData]);

  const getMaxDataValue = (maxDataVal) => {
    setMaxData(maxDataVal);
  };

  const getPaginationData = (initPageVal) => {
    setInitPage(initPageVal);
  };

  const handleGetFilters = (e) => {
    if (e.target.name === "document_plate") {
      setFilterData({
        ...filterData,
        [e.target.name]: e.target.value,
      });
    }

    if (
      e.target.name === "advisor" ||
      e.target.name === "insurer" ||
      e.target.name === "credit_state"
    ) {
      setFilterData({
        ...filterData,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === "next_to_renew") {
      const splittedValue = e.target.value.split("/");

      setFilterData({
        ...filterData,
        year:
          splittedValue[0] === ""
            ? new Date().getFullYear().toString()
            : splittedValue[1],
        month:
          splittedValue[0] === ""
            ? (new Date().getMonth() + 1).toString()
            : splittedValue[0],
      });
    }
  };

  const handleCleanFilters = () => {
    setFilterData({
      type: "Renovacion",
      document_plate: "",
      advisor: "",
      insurer: "",
      credit_state: "",
      year: new Date().getFullYear().toString(),
      month: (new Date().getMonth() + 1).toString(),
    });
  };

  const exportToExcel = () => {
    const jsonData = creditListExcel.map((credit) => {
      return {
        NombreCliente: credit.NombreCliente,
        Aseguradora: credit.Aseguradora,
        VigenciaInicial: credit.Inicio_poliza,
        NoDocumento: credit.Document,
        PlacaDetalleRiesgo: !credit.Placa ? credit.detalleRiesgo : credit.Placa,
        NoPoliza: credit.NoPoliza,
      };
    });

    const workbook = utils.book_new();

    const worksheet = utils.json_to_sheet(jsonData);

    utils.book_append_sheet(
      workbook,
      worksheet,
      "Creditos en proximos a renovar"
    );

    writeFile(workbook, "Creditos proximos a renovar.xlsx");
  };

  const formatNumber = (number, decimals) => {
    return number.toFixed(decimals);
  };

  return (
    <section className="renovation-table-section">
      <div className="renovation-table-subcontainer">
        <div className="rt-title-container">
          <h1 className="rt-title">Créditos Proximos a Renovar</h1>
        </div>
        <div className="rt-description-txt-container">
          <p className="rtdt-txt">
            Son los créditos vigentes al dia que se renuevan en menos de tres
            meses o cumplieron con el 100% de sus cuotas y se encuentran en paz
            y salvo.
          </p>
          <div className="stats-container">
            <div className="stats-subcontainer">
              <h1 className="stats-title">Total de Créditos</h1>
              <p className="stats-txt">{totalCreditCurrent}</p>
            </div>
            <div className="stats-subcontainer">
              <h1 className="stats-title">Total Renovaciones</h1>
              <p className="stats-txt">{totalCreditRenovation}</p>
            </div>
            <div className="stats-subcontainer">
              <h1 className="stats-title">Porcentaje de Renovación</h1>
              <p className="stats-txt">
                {formatNumber(
                  (totalCreditRenovation * 100) / totalCreditCurrent,
                  2
                )}
                %
              </p>
            </div>
          </div>
        </div>
        <div className="filter-elms-container">
          <div className="input-container">
            <form onChange={handleGetFilters} className="filter-form-container">
              <label>
                <h1 className="label-title">Número de Documento o Placa:</h1>
                <input
                  className="filter-input-search"
                  type="text"
                  placeholder="Numero de Documento o Placa"
                  title="Numero de Documento o Placa"
                  name="document_plate"
                  value={filterData.document_plate}
                />
              </label>
              {userData?.profile !== "Aseguradora" && (
                <label>
                  <h1 className="label-title">Aseguradora:</h1>
                  <select
                    name="insurer"
                    id="insurer"
                    disabled={filterData.document_plate !== "" ? true : false}
                    value={filterData.insurer}
                  >
                    <option value="">--Seleccione--</option>
                  </select>
                </label>
              )}
              <label>
                <h1 className="label-title">Proximos a Renovar:</h1>
                <select
                  name="next_to_renew"
                  id="next_to_renew"
                  disabled={filterData.document_plate !== "" ? true : false}
                  value={filterData.next_to_renew}
                >
                  <option value={nextToRenew}>--Seleccione--</option>
                </select>
              </label>
            </form>
            <button
              className="c-btn-search"
              title="Limpiar Filtros"
              onClick={handleCleanFilters}
            >
              Limpiar Filtros
            </button>
          </div>
        </div>
        <div
          className="cip-download-excel-container"
          style={{ display: creditList.length > 0 ? "unset" : "none" }}
        >
          <button
            title="Descargar Excel"
            className="cip-de-btn"
            onClick={() => exportToExcel()}
          >
            Descargar Excel
          </button>
        </div>
        <div className="credit-details-table-supercontainer">
          <CreditDetailsTableComponent
            getMaxData={getMaxDataValue}
            maxData={maxData}
            getPaginationInit={getPaginationData}
            credits={creditList}
            totalCreditsNum={totalCredits}
            filterData={filterData}
            fields={tableFields}
          />
        </div>
      </div>
    </section>
  );
};

export default RenovationTableBody;
